.spare_btn_rules {
  border: 1px solid var(--color-primary) !important;
  color: 1px solid var(--color-primary) !important;
  background-color: #ffffff !important;
  margin-left: auto;
}

.blackList-body {
  padding: 1em;
  position: relative;
  height: 100%;
  width: 100%;
}

.blackList-body_header {
  margin-bottom: 2em;
}

.blackList-body .list-dec {
  position: absolute;
  width: 0.5em;
  min-height: 6em;
  top: -0.5em;
  border-radius: 5px;
  left: 0.5em;
  background-color: var(--comet);
  height: calc(100vh - 30em);
  z-index: 99;
}

.blackList-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2em;
  position: relative;
  min-height: 6em;
  z-index: 500;
  margin-bottom: 2em !important;
}

.blackList-box-wrapper {
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  animation-name: FadeIn;
  animation-duration: 1s;
  transition-timing-function: linear;
  padding: 1em;
  animation: fade 0.5s ease-in-out;
  margin-bottom: 2em;
  position: relative;

}

.blackList-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 1em;
  font-weight: 900;
  width: 100%;
  padding: 1em;
  height: 3em;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.09);
  z-index: 500;
  position: relative;
  margin-left: 0.5em;
}

.blackList-body_list {
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-weight: 900;
  width: 100%;
  margin-top: 3em;
  position: relative;
  padding-left: 1em;
  min-height: 12em;
  height: 100%;
  overflow-y: scroll;
  scrollbar-width: none !important;
}

.blackList-body_list::-webkit-scrollbar {
  display: none;
}

.blackList-item::before {
  content: "";
  position: absolute;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: var(--color-primary);
  left: -1.3em;
  top: 50%;
  margin-top: -0.6em;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.black-item-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  gap: 1em;
}

.black-list-errors {
  position: absolute;
  left: 1em;
  bottom: 1em;
}

@media only screen and (max-width: 1024px) {
  .list-dec {
    display: none;
  }
  .blackList-body {
    padding: 1em 0 0 0;
  }
  .blackList-box {
    display: flex;
    flex-direction: column;
  }
}
