.navigations {
  width: 100%;
  height: 100%;
  border-right: 2px solid var(--comet);
  padding-right: 0.5em;
}

.navigations_list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  padding: 0 0.5em !important;

}

.navigations_list_item {
  height: 3.5em;
  padding: 1em 0.5em;
  text-decoration: none !important;
  border-bottom: 1px solid var(--colorLine);
  color: var(--comet);
  font-family: BoldFont, sans-serif;
}

.isActive {
  background-color: #f2f2f2;
  border-left: 5px solid var(--color-primary);
}

.navigations_list_item:hover {
  background-color: #f2f2f2;
  border-left: 5px solid var(--color-primary);
  color: var(--comet);
}

.navigations_list_item a:hover {
  background-color: #f2f2f2;
  color: var(--comet);
}

.navigations_list_item a {
  text-decoration: none !important;
  color: var(--comet);
  font-family: BoldFont, sans-serif;
  font-size: 1em;
  margin: 0;
}

.navigations_mobile {
  position: absolute;
  height: 100%;
  width: auto;
  right: 0;
  top: 4.5em;
  z-index: 2 ;
}

.navigations_mobile_btn {
  position: absolute;
  width: 2em;
  height: 2em;
  border-radius: 15px 0 0 15px;
  right: 0;
  background-color: var(--comet);
  color: #ffffff;
  display: grid;
  place-items: center;
  box-shadow: 5px 5px 2px rgb(0, 0, 0, 0.15);
  cursor: pointer;
  z-index: 0;

}

.navigations_mobile_list {
  z-index: 999999 !important;
  background-color: var(--color-primary);
  height: 100%;
  width: 0;
  transition: all 0.5s ease-in;
  border-radius: 15px;
  display: none;
  border: 1px solid var(--manhattan);
}

.expand {
  width: auto !important;
  transition: all 0.5s ease-in;
  display: flex;
  flex-direction: column;
}

.navigations_mobile_list_item {
  height: 3.5em;
  padding: 1em 0.5em;
  text-decoration: none !important;
  color: #ffffff !important;
  font-family: BoldFont, sans-serif;
}

.navigations_mobile_list_item a {
  color: #ffffff !important;
}

.mobile_isActive {
  background-color: var(--manhattan);
  border-left: 1px solid var(--color-primary);
  border-top: 1px solid var(--color-primary);
  border-radius: 10px 0 0 10px;
  position: relative;
}


.mobile_isActive::after {
  content: "";
  position: absolute;
  width: 1em;
  height: 1em;
  background-color: transparent;
  right: 0;
  bottom: -1em;
  border-radius: 0 25px 0 0;
  box-shadow: 4px -5px 0 var(--manhattan);

}
