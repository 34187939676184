.account {
  width: 100%;
  height: 100%;
  padding: 1em;
}

.account_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  align-items: center;
  height: 4em;
  width: 100%;
  border-bottom: 1px solid var(--colorLine);
  gap: 0.5em;
}

.dialog-footer {
  gap: 0.5em;
}

.account_header h4 {
  font-size: 1.2em;
  font-family: GraphikBold, sans-serif;
  margin: 0;
}

.account_body {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 60% 40%;
  overflow-y: scroll;
  height: calc(100% - 10em);
  scrollbar-width: none !important;
}

.account_body::-webkit-scrollbar {
  display: none;
}

.account_body_list {
  display: flex;
  flex-direction: column;
  padding-left: 2em;
}

.account_body_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3em;

}

.account_body_item label {
  font-family: BoldFont, sans-serif;
}

.account_body_item p {
  margin: 0;
}

@media only screen and (max-width: 1024px) {


  .account_body {
    display: flex !important;
    flex-direction: column-reverse;
    height: calc(100vh - 5em);
    padding-bottom: 10em;
  }
}
