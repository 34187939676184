.social-container {
  width: 100%;
  height: 5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  max-width: 8em;
  margin: 0 auto;
  color: var(--color-primary) !important;
}
.social-container a {
  color: var(--color-primary) !important;
}

.social-container * {
  cursor: pointer;
}