.chart_card {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid var(--colorLine);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 1em;
}

.chart_card_header {
  display: flex;
  flex-direction: column;
  font-family: GraphikBold, sans-serif;
  justify-content: center;
  align-items: flex-start;
  margin-block-end: 2rem;

}

.chart_card_header_title {
  font-size: 1.2em;
  font-family: GraphikBold, sans-serif;
}

.chart_card_header_value {
  color:var(--allGreen);
  font-size: 1.5em;
  font-family: GraphikBold, sans-serif;
}

.chart_card_body {
  display: flex;
  flex-direction: column;
}

.chart_card_body_description {
  text-decoration: underline;
  font-family: BoldFont, sans-serif;
  font-size: 1em;
  font-weight: 800;
}

.chart_card_body_chart-container {
  width: 100%;
  position: relative;
  min-height: 300px;

}

.chart_card_body_chart-container img {
  width: 4em;
  height: 4em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -2em;
  margin-top: -2em;
}

@media only screen and (max-width: 1024px) {
  .chart_card_body_chart-container {
    min-height: auto;
  }
}
.p-chart{
  width: 100%;
}
.title_download-btn{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-right: 1.2rem
}


.legend{
  position: relative;
  display: inline-flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 1rem;
  margin-right: 1rem;
  .legend_circle{
    margin-right: .4rem;
  }
  .legend_circle::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #00a4a03d;
  }
  .legend_title{
    font-size: .9rem;
  }

 
  
}

