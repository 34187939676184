.settings {
  width: 100%;
  height: calc(100% - 2.5em);
  overflow-y: scroll;
  scrollbar-width: none !important;
}

.settings::-webkit-scrollbar {
  display: none !important;
}

