.customer-information {
  padding: 1em 1em 5em 1em;
  height: 70vh;
  overflow-y: scroll;
}

.customer-information_description {
  background-color: var(--comet);
  color: #ffffff;
  font-family: BoldFont, sans-serif;
  padding: 1em;
  font-size: 1em;
  border-radius: 10px;
  border: 2px solid var(--manhattan);
}

.informations {
  display: flex;
  flex-direction: row;
  gap: 1em;
  margin-top: 1em;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  flex-wrap: wrap;
}

.informations_text {
  font-family: BoldFont, sans-serif;
  color: var(--comet);
  margin-left: 1em;
}

.informations_item {
  width: 12em;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.field-wrapper {
  position: relative;
  min-height: 300px;
  border: 1px solid var(--colorLine);
  border-radius: 25px;
  padding: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  gap: 0.5em;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.option-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: stretch;
  align-items: center;
  gap: 0.5em;
}

.option-input {
  width: 100%;
}

.remove_btn {
  position: absolute;
  right: 0;
  top: 0;
}

.remove_btn_option {
  position: relative;
  right: 0;
  top: 0;
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 1em;
  position: relative;
  height: 100%;
  min-height: 280px;
}

.add_option_btn {
  margin-right: 0;
  margin-top: auto;
  padding-top: 0.5em;
}

.field-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.field-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  border-top: 1px solid var(--colorLine);
  padding-top: 0.5em;

}

.settings-container_loader {
  width: 6em;
  height: 6em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -3em;
  margin-top: -3em;
}

.settings-container_loader img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.alert-message-text {
  position: absolute;
  left: 0;
  bottom: 5em;
  padding: 2em;
  background-color: var(--comet);
  color: #FFFFFF;
  border: 1px solid var(--manhattan);
  border-radius: 5px;

}

