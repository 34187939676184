.last-payments {
  background-color: #ffffff;
  border-radius: 15px;
  border: 1px solid var(--colorLine);
  padding: 0.5em;
  position: relative;
}

.last-payments_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  height: 3em;
}

.last-payments_header a {
  text-decoration: none !important;
}

.last-payments_header_title {
  font-family: GraphikBold, sans-serif;
  font-size: 1.1em;
  color: var(--comet);
}

.last-payments_header {
  color: var(--manhattan);
  font-size: 1.1em;
  font-family: BoldFont, sans-serif;
}

.payments_table {
  width: 100%;
  height: 100%;
}

.payments_table td {
  font-size: 1em;
  font-family: BookFont, sans-serif;
  height: 55px;
  border-bottom: none !important;
  padding: 1em;
  vertical-align: middle !important;
}

.payments_table th {
  font-size: 1em;
  font-family: BoldFont, sans-serif;
  height: 55px;
}

.payments_table th:nth-child(1) {
  width: 50%;
}

.payments_table th:nth-child(2) {
  width: 25%;
}

.payments_table th:nth-child(2) {
  width: 25%;
}

.payments_table td:nth-child(1) {
  width: 25%;
}

.payments_table td:nth-child(2) {
  width: 25%;
}

.payments_table td:nth-child(3) {
  width: 25%;
}

.payment-status-dot {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  margin-right: 5px;
}

.payment-status-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  border-bottom: none !important;
}

@media only screen and (max-width: 1024px) {
  .payments_table td:nth-of-type(1):before {
    content: "";

  }

  .payments_table td:nth-of-type(2):before {
    content: "Date";
  }

  .payments_table td:nth-of-type(3):before {
    content: "Amount";
  }
}