:root {
  --business-color: #5F6285;
  --background-color-1: #7884fA;
  --background-color-2: #F3F6FA;
}

#form {
  flex: 0 0 50% !important;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
}

#loader {
  height: 120%;
  width: 90%;
  position: absolute;
  top: -10%;
  opacity: 0;
}

.showLoader {
  opacity: 1 !important;
}

.cursor {
  cursor: pointer;
}

.cursor-normal {
  cursor: default;
}

.business-title {
  font-size: 1.3em;
  font-family: GraphikBold, Times, serif;
  color: var(--business-color);
  margin: 0.5em 0 1em 0;
}

.wrapper {
  width: 100%;
  height: 100%;
  background: rgb(243, 246, 250) linear-gradient(180deg, rgba(243, 246, 250, 1) 78%, rgba(120, 132, 250, 1) 78%);
  display: grid;
  justify-content: center;
  align-items: flex-end;
  overflow-y: hidden;
}

.wrapper * {
  box-sizing: border-box;
}

.content-container {
  display: flex;
  flex-direction: row;
  position: relative;
}

.logo {
  width: 6em;
  height: 6em;
  padding: 0.5em;
  margin: auto;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.form-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 0;
  margin-bottom: 3em;
}

.form-header span {
  margin: 0;
  color: var(--business-color);

}

.welcome-text {
  font-family: BoldFont, Times, serif !important;
  font-size: 1.3em;

}

.instruction-text {
  font-family: BookFont, Times, serif !important;
  font-size: 1.3em;
}

.form-wrapper {
  background-color: #fff;
  border-radius: 50px 50px 0 0;
  padding: 2.5em 1.5em;
  gap: 1em;
  height: 60vh;
  z-index: 99;
  position: relative;
}

.book-font {
  font-family: BookFont, Times, serif !important;
}

.book-bold-font {
  font-family: BoldFont, Times, serif !important;
}

.graphik-m-font {
  font-family: BoldFont, serif !important;
  font-size: 16pt;
}

.graphik-r-font {
  font-family: BoldFont, serif !important;
  font-size: 16pt;
}

.page-info {
  margin: 0;
  line-height: 1.2;
  text-align: center;
  z-index: 9999;
}

.form-group {
  display: flex;
  flex: 0 0 auto;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 1.5rem;
}

.form-group label {
  margin-bottom: 0.5em;
  font-family: RegularGraphik, Times, serif !important;
  color: var(--business-color);
  font-size: 13pt;
}

.help-section span {
  color: var(--business-color) !important;
}

.form-control {
  display: block;
  width: 100%;
  height: 4em;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: var(--business-color);
  font-family: RegularGraphik, Times, serif !important;
  font-weight: 700;
  background-color: var(--background-color-2);
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0.5rem;
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}


.btn {
  width: 100%;
  border: none;
  text-align: center;
  padding: 1em 1.5em;

}

.login-btn {
  color: #fff;
  background-color: var(--business-color);
  border-radius: 10px;
  font-size: 1.2em;
  margin: 3em 0 1em;
  box-sizing: border-box;
}

.login-btn:active {
  border: 2px solid var(--background-color-2);

}

.login-btn:focus {
  border: 2px solid var(--background-color-2);
}

.checkbox-group {
  margin-top: 5px;
}

.checkbox-group label {
  margin: 0;
}

.help-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5em;
  position: relative;
  z-index: 99999;
}

.help-section span:nth-child(1) {
  font-size: 0.9em;
  font-weight: 900;
  font-family: BoldFont, Times, serif !important;
  color: #a2a2a3;
}

.help-section span:nth-child(2) {
  font-size: 0.9em;
  font-weight: 200;
  font-family: BookFont, Times, serif !important;
  color: #b6b6b7;
}

.illustration {
  position: relative;
  z-index: 2;
  width: 18em;
}

.illustration-right {
  width: 18em;
}

.illustration-left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  z-index: 5;
  left: 2em;
  top: 0;
}

.illustration-right img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  z-index: 5;
  left: -2em;
  top: 0;
}

.checkbox-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1em;


}

.checkbox-group span {
  font-family: BookFont, Times, serif !important;
  color: var(--business-color);
  font-size: 0.9em;
}

.round {
  position: relative;
  display: grid;
  place-items: center;
}

.round label {
  background-color: #fff;
  border: 1.5px solid var(--business-color);
  border-radius: 50%;
  cursor: pointer;
  height: 12px;
  left: 0;
  top: 0;
  position: absolute;
  width: 12px;
}

.checkbox-group span {
  font-family: BoldFont, serif !important;
  margin-left: 0.5em;
}


.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: var(--business-color);
  border-color: #fff;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}


@media only screen and (max-width: 1440px) {

  .wrapper {
    overflow: hidden;
    background: linear-gradient(180deg, rgba(243, 246, 250, 1) 81%, rgba(120, 132, 250, 1) 81%);
  }

  .form-wrapper {
    height: 61vh;
  }

  .login-btn {
    margin-top: 1.8em;
  }

  .illustration-right img {
    top: -1em;
  }

  .illustration-left img {
    top: 0;
  }


}


@media only screen and (max-width: 768px) {

  .business-title {
    color: #fff;
    display: none;
  }

  .page-info {
    text-align: left;
  }


  .wrapper {
    position: relative;
    background: none var(--background-color-1) !important;
    background-color: var(--background-color-1) !important;
  }

  .content-container {
    margin-top: 20%;
    height: 100%;
  }

  .form-container {
    padding: 0 2em;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  }

  .form-group label {
    font-size: 0.9em;
  }

  .logo {
    width: 5em;
    height: 6em;
    margin: 0 0 2em 0;
  }

  .form-header {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1.5em;
    margin-bottom: 8em;
  }

  .form-wrapper {
    width: 100%;
    height: 72vh;
  }

  .form-header span {
    color: #fff;
    font-size: 1.1em;
    z-index: 2;
  }


  .man-img {
    position: absolute;
    object-fit: contain;
    z-index: 2;
    width: 100%;
    height: 100%;
    right: -30%;
    top: -1%;


  }

  .woman-img {
    position: absolute;
    object-fit: contain;
    z-index: 2;
    width: 100%;
    height: 100%;
    margin-top: 1em;
    right: -25%;


  }

  .form-control {
    height: 3em;
  }

  .btn {
    padding: 0.5em 1em;
  }

  .login-btn {
    margin-top: 2em;
  }


}

@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .business-title {
    color: #fff;
    font-size: 1.8em;
    display: none;
  }

  .page-info {
    text-align: left;
  }

  .wrapper {
    grid-template-columns: 1fr;
  }

  .content-container {
    width: 100%;
    height: 100%;
    margin-top: 15%;
  }

  .form-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .wrapper {
    position: relative;
    background: none var(--background-color-1) !important;
    background-color: var(--background-color-1) !important;
  }

  .form-container {
    padding: 0 2em;
    position: relative;
  }

  .form-group label {
    font-size: 1.5em;
  }

  .form-control {
    height: 4em;
  }

  .login-btn {
    font-size: 1.5em;
  }

  .logo {
    width: 8em;
    height: 8em;
    margin: 0 0 2em 0;
  }

  .form-header {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 1.5em;
    margin-bottom: 8em;
  }

  .form-wrapper {
    width: 100%;
    position: relative;
    height: 72vh;
  }

  .form-header span {
    color: #fff;
    font-size: 2em;
    z-index: 2;
  }


  .man-img {
    position: absolute;
    object-fit: contain;
    z-index: 2;
    width: 100%;
    height: 100%;
    right: -30%;
    top: -1%;


  }

  .woman-img {
    position: absolute;
    object-fit: contain;
    z-index: 2;
    width: 100%;
    height: 100%;
    right: -31%;


  }

}

@media only screen and (max-width: 320px) {
  .form-header {
    margin-bottom: 1.5em;
  }

  .logo {
    height: 4em;
    width: 4em;
    margin-bottom: 0.5em !important;
  }

  .login-btn {
    margin-top: 0.8em;
  }

  .form-wrapper {
    border-radius: 25px 25px 0 0;
  }

  .form-control {
    height: 2em;
    border-radius: 5px;

  }

  .content-container {
    margin-top: 5%;
  }

  .business-title {
    margin-bottom: 0.5em;
    font-size: 1.2em;
  }

}

@media only screen and (max-width: 378px) {
  .woman-img {
    height: 80%;
    width: 100%;
  }
}

@media only screen and (max-width: 415px) {
  .field {
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
  }

  .field .error-partial {
    padding: 0;
  }

  .content-container {
    margin-top: 8%;
  }

  .form-header {
    margin-bottom: 0;
    margin-top: 20%;

  }

  .logo {
    margin-bottom: 0.5em !important;
  }

  .form-wrapper {
    border-radius: 40px 40px 0 0;
    margin-top: auto;
    width: 100%;
  }

  .business-title {
    margin-bottom: 0.8em;
    font-size: 1.2em;
  }

  .login-btn {
    margin-top: 1em;
    border-radius: 5px;
  }

  .form-control {
    font-size: 10pt;
    font-family: RegularGraphik, serif !important;
  }

  .woman-img {
    height: 75%;
    width: 100%;
    right: -28%;
    margin-top: 0;
  }

}

@media only screen and (max-height: 667px) {
  .form-header {
    margin-top: 5%;
  }

  .woman-img {
    height: 95%;
    width: 100%;
  }
}

.form-wrapper .field {
  padding: 0;
  margin: 0;
}

.field-validation-error {
  display: block !important;
  width: 100%;
}
.password-helper-wrapper {
  position: relative;
}
.password-helper {
  position: absolute;
  bottom: 1em;
  right: 1em;
}


.form-container .errors {
  position: absolute !important;
}