
.App {
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    height: 100%;
    scrollbar-width: none !important;
}

.App::-webkit-scrollbar {
    display: none !important;
}


@media only screen and (max-width: 1024px) {

    .table {
        overflow: scroll;
        height: 100% !important;
        z-index: 1 !important;
    }

    .table-responsive thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
        font-family: BookFont, serif !important;
    }

    .table-responsive tr {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        font-family: BookFont, serif !important;
        border: none;
        margin-bottom: 1em;
        -webkit-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.26);
        -moz-box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.26);
        box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.26);
        z-index: 1 !important;
    }

    .table-responsive td {
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        padding-left: 50%;
        text-align: right !important;
        background-color: #fff;
        width: 100% !important;
        font-family: BookFont, serif !important;
    }

    .table-responsive td:before {
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 16px;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        text-align: left;
    }

}

