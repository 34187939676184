.apiKeys-body {
  width: 100%;
  height: 100%;
  padding: 1em;
}

.apiKeys-body_description {
  border: 1px solid var(--manhattan);
  background-color: var(--comet);
  color: #ffffff;
  border-radius: 10px;
  padding: 1em;
  font-family: BoldFont, sans-serif;
}

.apiKeys-body_table {
  margin-top: 1em;
}

#apiKeys_form_textarea {
  height: 8em !important;
}

.apiKeys_table_action {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: .5rem;
}

.apiKeys-body_table th:nth-child(1) {
  width: 25%;
}

.apiKeys-body_table th:nth-child(2) {
  width: 20%;
}

.apiKeys-body_table th:nth-child(3) {
  width: 25%;
}

.apiKeys-body_table th:nth-child(4) {
  width: 5%;
}

.apiKeys-body_table th:nth-child(5) {
  width: 15%;
}
.callback-url-content{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  .url-content{
    margin-inline-end: .5rem;
  }
}
@media only screen and (max-width: 1024px) {
  .apiKeys-body {
    padding: 0;
  }
  .callback-url-content{
    justify-content: flex-end;
  }

  .apiKeys-body_table td:nth-of-type(1):before {
    content: "App id";
  }

  .apiKeys-body_table td:nth-of-type(2):before {
    content: "Api key";
  }

  .apiKeys-body_table td:nth-of-type(3):before {
    content: "CallBack Url";
  }
  .apiKeys-body_table td:nth-of-type(4):before {
    content: "State";
  }
  .apiKeys-body_table td:nth-of-type(5):before {
    content: "Date";
  }
  .apiKeys-body_table td:nth-of-type(6):before {
    content: "";
  }


}

.td-padding {
  padding-left: 0.5em;
}

.required-dot::after{
  content: "*";
  margin-inline-start: .3rem;
  color:var(--blushPink);
  font-size: 1.2rem;
}

.edit-callback{
  cursor: pointer;
  margin-left: 0.2rem;
}



