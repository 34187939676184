.pie-chart {
  width: 100%;
  height: 100%;
  border: 1px solid var(--colorLine);
  background-color: #ffffff;
  border-radius: 15px;
  padding: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  justify-content: space-between;
}

.pie-chart_header {
  font-family: GraphikBold, sans-serif;
  font-size: 1.2em;
  margin-bottom: 1em;
}

.pie-chart_container {
  width: 100%;
  height: 100%;
}
