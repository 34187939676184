.Invoice {
  width: 100%;
  height: calc(100% - 4em);
  padding: 2em;
}

.Invoice_card {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  padding: 1em;
  border: 1px solid var(--colorLine);
  border-radius: 15px;
}

.Invoice_card_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
  margin-bottom: 1em;
  border-bottom: 1px solid var(--colorLine);

}


.Invoice_table {
  margin-top: 1em;
}

.invoice-actions{
  display: flex;
  gap: .75rem;
  justify-content: center;
  align-content: center;
}

@media only screen and (max-width: 1024px) {
  .Invoice {
    height: 100%;
    padding: 1em 0.5em !important;
  }
  .Invoice_card {
    height: auto;
    padding: 1em;
    position: relative;
  }
  .Invoice_card_header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5em;
  }
  #create_invoice {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
  }
  .invoice-actions{
    width: 100%;
    justify-content: space-between;
    margin-block: .5rem;
    
  }
}

@media only screen and (max-width: 560px) {
  .invoice-actions {
    flex-direction: column-reverse;

    button {
      width: 100%;
      justify-content: center;
    }
  }
}