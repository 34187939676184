.loading{
    margin-bottom: 1.5rem;
    width: 100%;
}
.loading:first-child{
    margin-top: 2rem;
}
.row:first{
    --bs-gutter-x:0!important
}
.row{
    margin-left:0!important
}