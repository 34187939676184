.month-date-picker {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  max-width: 22em;
  position: absolute;
  top: 1em;
  right: 5em;
}