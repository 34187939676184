.invoice-info {
  padding-top: 1em;
  padding-bottom: 5em;
}

.invoice-card {
  min-height: 95%;
  height: 100%;
  width: 80%;
  margin: auto;
  padding: 1em 2em;
}

.invoice-header {
  width: 90% !important;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
  flex-wrap: wrap;
  position: relative;
  padding-top: 1em;
}

.invoice__title {
  font-family: GraphikBold, serif !important;
  font-size: 3em;
  color: #000;
}

.invoice-header label {

  font-size: 0.9em;
}

.invoice-header p {
  font-family: GraphikBold, serif !important;
  color: #000;
}

.invoice_logo {
  width: 12em;
  height: 12em;
  position: absolute;
  top: 1em;
  left: 5em;
  padding: 0.2em;

}

.invoice_logo img {
  object-fit: fill;
  width: 100%;
  height: 100%;
}


.items-section {
  min-height: 300px;
  margin-bottom: 2em;
}

.align-center {
  text-align: center !important;
}

.invoice-info table th, .invoice-info td {
  font-family: GraphikBold, serif !important;
  color: #000;
  font-size: 1.2em;
}

.invoice-info .table thead th {
  font-family: GraphikBold, serif !important;
  color: #000;
  font-size: 1.2em;
}

.invoice__total {
  width: 100%;
  margin: auto;
  height: 6em;
}

.invoice__total-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

}

.invoice__total-wrapper {
  border-top: 1.5px solid #000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 0 0 50%;
  padding: 1em;
}

.invoice_sub-total {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #575656;
  width: 60%;
}

.invoice__sub-total-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.invoice__total-value {
  width: 60%;
  float: right;
}

.invoice__total-value h4 {
  font-family: GraphikBold, serif !important;
  color: #000;
}

.invoice-qr {
  width: 8em;
  height: 8em;
  text-align: center;
  font-family: GraphikBold, serif !important;
  color: #000;
}

.invoice-qr-code {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.invoice-signature {
  width: 60%;
  float: right;
  margin-top: 4em;
}

.invoice-signature h4 {
  font-family: GraphikBold, serif !important;
  color: #000;
  text-align: center;
}

.customer-name {
  font-family: GraphikBold, serif !important;
  color: #000;
}

.invoice-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  padding-bottom: 3em;

}

.invoice-signature-title {
  border-top: 1px solid #000;
  padding: 1em;
  width: 50%;
  margin: auto;
}

.bold-text {
  font-family: GraphikBold, serif !important;
  color: #000;
}

.footer-spacer {
  height: 10em;
  margin-top: 1em;
}

.pdf-btn {
  float: right;
  padding: 0.5em;
  width: 100px;
  color: #FFFFFF;
  background-color: var(--color-primary);
  border: none;
  border-radius: 5px;
}

.item-description {
  font-family: BookFont, serif !important;
  color: #000;
}

@media only screen and (max-width: 800px) {

  .invoice-card {
    width: 99%;
  }

  .invoice_logo {
    position: relative;
    top: 0;
    left: 0;
  }
  .invoice-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding-top: 1em;
  }


  .table {
    overflow: scroll;
    height: 100% !important;
  }

  .invoice-info .table-responsive2 > table,
  thead,
  tbody,
  th,
  td,
  tr {

    font-family: BookFont serif !important;
  }

  .table-responsive2 thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table-responsive2 tr {
    display: flex;
    flex-direction: column;
    width: 100%;
    border: 1px solid #ccc;
    position: relative;
  }

  .table-responsive2 tr .nav-link {
    display: none !important;
  }

  .table-responsive2 tr .dropdown-menu {
    display: none;
  }


  .invoice-info .table-responsive2 td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    text-align: right;
    background-color: #fff;
    width: 100%;
    font-family: GraphikBold, serif !important;
  }

  .invoice-info .table-responsive2 td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 16px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    text-align: left;
    font-family: GraphikBold, serif !important;
  }

  .table-responsive2 td:nth-of-type(1):before {
    content: "Description";
  }

  .table-responsive2 td:nth-of-type(2):before {
    content: "Unit";
  }

  .table-responsive2 td:nth-of-type(3):before {
    content: "Price";
  }

  .table-responsive2 td:nth-of-type(4):before {
    content: "Tax";
  }
  .table-responsive2 td:nth-of-type(5):before {
    content: "Amount";
  }

  .invoice__total-wrapper {
    flex: 0 0 100%;

  }
  .invoice_sub-total {
    width: 100%;
  }
  .invoice__total-value {
    width: 100%;
  }
  .invoice-signature-title {
    font-size: 0.9em;
    width: 100%;
  }
  .invoice-qr {
    width: 100%;
    height: 100%;
    flex: 0 0 24%;
  }
  .invoice-qr-code {
    width: 4em;
    height: 4em;

  }
  .pdf-btn {
    position: absolute;
    top: 5px;
    right: 5px;

  }
  .card-header {
    margin-bottom: 1em;
  }

}

.loader-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 5em;
  height: 5em;
  margin-left: -2.5em;
  margin-top: -2.5em;
}

.loader-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}