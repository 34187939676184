.spare-btn{
    text-align: center;
}
.spare-btn:disabled,.spare-btn:active{
    box-shadow: none;
}


.primary{
    background: var(--color-primary);
}
.primary:hover{
    background: var(--color-primaryHover);
}

.secondary{
    background: var(--color-secondary);
}   
.secondary:hover{
    background: var(--color-secondaryHover);
    color: var(--comet);
}

.warning{
    background: var(--color-warning);
}   
.warning:hover{
    background: var(--color-warningHover);
}.danger{
    background: var(--color-danger);
}   
.danger:hover{
    background: var(--color-dangerHover);
}

.spare-btn.small{
    font-size: .8rem;
}
.spare-btn.large{
    font-size: 1.2rem;
}
