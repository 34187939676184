
.rules-wrapper {
  width: 100%;
  height: 100%;
  padding: 1em;
}

.rules-body-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

}

.rules-helper {
  position: absolute;
  left: -1em;
  top: 0;
  z-index: 999;
  display: flex;
  flex-direction: row;
}

.rules-helper-btn {
  color: #ffffff;
  background-color: var(--color-primary);
  width: 1.5em;
  height: 1.5em;
  border-radius: 0 15px 15px 0;
  display: grid;
  place-items: center;


}

.rules-helper-description {
  background-color: var(--color-primary);
  width: 0;
  height: 25em;
  overflow-y: scroll;
  border-radius: 15px 0 15px 15px;
  opacity: 0;
  transition: width 350ms ease,
  opacity 350ms ease;
  color: #ffffff;
}

.rules-helper-description_show {
  background-color: var(--color-primary);
  width: 45em;
  padding: 1em;
  height: 25em;
  overflow-y: scroll;
  border-radius: 15px 0 15px 15px;
  opacity: 1;
  transition: width 350ms ease,
  opacity 350ms ease;
  color: #ffffff;
  scrollbar-width: none !important;
}

.rules-helper-description_show::-webkit-scrollbar {
  display: none !important;
}

.rules-helper-description_show h5 {
  font-family: BoldFont, sans-serif;
}

.rules-helper-description_show p {
  font-family: BookFont, sans-serif;
}


@keyframes expand {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.blackList-body_header {
  display: flex;
  flex-direction: row;
  margin-bottom: 1em;
}

.spare_btn_rules {
  border: 1px solid var(--color-primary) !important;
  color: 1px solid var(--color-primary) !important;
  background-color: #ffffff !important;
  margin-left: auto !important;
  width: 120px !important;
  border-radius: 5px;
  padding: 0.5em 1em;
}

.rules-body {
  display: flex;
  flex-direction: column;
  gap: 1em;
  font-weight: 900;
  width: 100%;
  margin-top: 2em;
  position: relative;
  align-items: flex-start;
  min-height: 12em;
  height: 100%;
  overflow-y: scroll;
}

.rule-item {
  display: grid;
  grid-template-columns: 50% 25% 23%;
  grid-auto-rows: 1fr;
  justify-content: flex-start;
  gap: 1em;
  font-weight: 900;
  padding: 1em;
  height: 3em;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.09);
  z-index: 500;
  position: relative;
  margin-left: 1.5em;
  text-justify: distribute;
}

.rule-item::before {
  content: "";
  position: absolute;
  width: 1.2em;
  height: 1.2em;
  border-radius: 50%;
  border: 1px solid #ffffff;
  background-color: var(--color-primary);
  left: -1.3em;
  top: 65%;
  margin-top: -0.6em;
}

.rule-item-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
}

.rule-empty-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  text-align: center;
  margin: auto;
  font-size: 1.2em;
}

.rules-body .list-dec {
  position: absolute;
  width: 0.5em;
  min-height: 6em;
  top: -0.5em;
  border-radius: 5px;
  left: -0.5em;
  background-color: var(--comet);
  height: calc(100vh - 30em);
}

@media only screen and (max-width: 1440px) {
  .rule-item {
    align-items: center;
    height: 6em;
    grid-template-columns: 50% 24% 22%;
  }
}

@media only screen and (max-width: 1024px) {
  .rule-item {
    grid-template-columns: 60% 19% 12%;
    align-items: center;
    height: 6em;
  }
}

.rule-box {
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);
  min-height: 6em;
  width: 100%;
  padding: 1em;
  font-weight: 800;
  gap: 1em;
  justify-content: space-between;
  margin-bottom: 3em !important;
  animation-name: FadeIn;
  animation-duration: 1s;
  transition-timing-function: linear;
}

.rule-box_options {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  width: 100%;
  position: relative;
  z-index: 500;
}

.rule-box_side {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.rule-box_score {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;

}

.rule-box_footer {
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
}

.add-rule-container {
  transition: all 0.5s ease-in;
  animation: fade 0.5s ease-in;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.rules-body::-webkit-scrollbar {
  width: 0 !important;
}

.rules-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.rules-body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid var(--comet);
}