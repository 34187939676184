.settings-container {
  width: 100%;
  height: 100%;
  padding: 1em;
}

.settings-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4em;
  border-bottom: 1px solid var(--colorLine);
  gap: 0.5em;
}

.settings-header h4 {
  font-size: 1.2em;
  font-family: GraphikBold, sans-serif;
  margin: 0;

}