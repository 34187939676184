.Invoice {
  width: 100%;
  height: calc(100% - 4em);
  padding: 2em;
}

.invoice_add_card {
  width: 80%;
  margin: auto;
  height: 100%;
  background-color: #ffffff;
  padding: 1em;
  overflow-y: scroll;
}

.invoice_add_header {
  padding: 1em 0;
  border-bottom: 1px solid var(--colorLine);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

}

.invoice_add_header h4 {
  color: var(--comet);
  font-size: 1.2em;
  font-family: GraphikBold, sans-serif;
}

.invoice_add_body_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  flex-wrap: wrap;
  margin-bottom: 2em;
}

.invoice_add_body_item {
  width: calc(50% - 1em);
}

.items-headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-primary);
  padding: 1em;
}

.items-headers h4 {

  font-size: 1em;
  margin: 0;
  color: #ffffff;
}

.items-headers div {
  flex: 0 0 19%;
}

.items {
  border-radius: 15px 15px 0 0 !important;
}

.items thead {
  background-color: var(--color-primary);
  padding: 1em;
  color: #ffffff;
  height: 3em;

}

.items th {
  vertical-align: middle;
  padding-left: 2em !important;
}

.items th:nth-child(1) {
  width: 15%;
}

.items th:nth-child(2) {
  width: 35%;
}

.items th:nth-child(3) {
  width: 15%;
}

.items th:nth-child(4) {
  width: 15%;
}

.items th:nth-child(5) {
  width: 15%;
}

.items th:nth-child(6) {
  width: 5%;
}

.invoice-item-wrapper {
  width: 100%;
  position: absolute;
}