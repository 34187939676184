.filter-search{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.3em;
    &_icon{
    cursor: pointer;
      background-color: var(--color-primary);
      color: white;
      padding-block: .32rem;
      padding-inline: .75rem;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
    &_input{
      padding-inline: .5em;
      padding-block: .2em;
      border:.5px solid var(--colorLine);
      border-left: none;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      transition : border 250ms ease-out;
    }
    &_input:focus,&_input:hover{
      outline: none;
      border-color: var(--color-primary);
    }
  }

  @media only screen and (max-width:1024px) {
    
    .filter-search{
      &_input{
        width: 100%;
      }
    }      
  }
  @media screen and (max-width: 560px){
    .filter-search{
      width: 100%;
    }
  }