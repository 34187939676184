.code-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  background: rgb(243, 246, 250) linear-gradient(180deg, rgba(243, 246, 250, 1) 79%, rgba(120, 132, 250, 1) 79%);
}

.code-card {
  width: 25%;
  min-height: 55vh;
  padding: 1em 1em 0 1em;

}

.form-wrapper {
  background-color: #FFFFFF;
}