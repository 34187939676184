@import './fonts.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';
@import 'react-date-range/dist/styles.css';
@import 'react-date-range/dist/theme/default.css';
@import 'react-toastify/dist/ReactToastify.css';

@import "primereact/resources/themes/md-light-deeppurple/theme.css";  //theme
@import "primereact/resources/primereact.min.css";                  //core css
@import "primeicons/primeicons.css";  
:root {
  --color-primary: #4f5170;
  --color-primaryHover:#5b688e;
  
  --color-secondary: #8B95F4;
  --color-secondaryHover:#E1E4FF;

  --color-warning: #EEA888;
  --color-warningHover:#eea8889c;

  --color-danger: #BB5282;
  --color-dangerHover:#bb528198;

  --colorPrimaryDark: #545673;
  --comet: #030732;
  --lightRoyalBlue: #293cff;
  --jade: #00bc42;
  --ultraMarine: #0e0cae;
  --blackBlue: #040732;
  --blueSpare: #6874f1;
  --blue: #2431FF;
  --white: #ffffff;
  --selago: #f3f5ff;
  --selago_70: #B3F3F5FF;
  --selago_65: #65F3F5FF;
  --selago_30: #4DF3F5FF;
  --olsoGray: #6D767E;
  --romantic: #ffd6bf;
  --yblackRock: #030732;
  --black: #000000;
  --lightBlue: #004cef;
  --blushPink: #b30014;
  --blushPinkLight: #c23343;
  --royalBlue: #4a64f5;
  --portage: #5867EE;
  --portageSpare: #6774ED;
  --portage_50: #408b95f4;
  --portage_15: #158b95f4;
  --paleLilac: #e2e4ff;
  --paleGrey: #ebecf1;
  --paleGrey_05: #80ebecf1;
  --olsoGray_05: #8054595E;
  --disabled: #99808080;
  --perano: #b5b2f1;
  --gray: #888888;
  --colorLine: #dfdfdf;
  --manhattan: #f6c1a7;
  --cranberry: #d96682;
  --lightRoyalBlue_50: #80293CFF;
  --roseBud: #f9b5ac;
  --roseBud_50: #80F9B5AC;
  --roseBud_20: #33F9B5AC;
  --mulberry: #bb5282;
  --dialogBlue: #0053B3;
  --dialogLine: #cdced2;
  --success: #5da57c;
  --iosBlue: #0777FA;

  --allGreen:#68A593
}

html, body, #root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;

}

@import "./buttons.scss";
@import "./forms";
@import "./dailog";

body {
  margin: 0;
  font-family: BookFont,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--selago) !important;
}

body::-webkit-scrollbar {
  width: 0.5em !important;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid var(--comet);
}


.rdrInRange, .rdrStartEdge, .rdrEndEdge {
  background-color: var(--color-primary) !important;
  color: var(--color-primary) !important;
}

.rdrDay:hover {
  border-color: var(--color-primary) !important;;
}

.rdrDateInput {
  border-color: var(--color-primary) !important;
}

#root > div {
  height: 100% !important;
}

.cursor {
  cursor: pointer;
}

.progress {
  height: 0.5em;
}

.progress-bar.indeterminate {
  position: relative;
  animation: progress-indeterminate 1s linear infinite;
  height: 0.5em;
  background-color: var(--color-primary);
}

@keyframes progress-indeterminate {
  from {
    left: -25%;
    width: 25%;
  }
  to {
    left: 100%;
    width: 25%;
  }
}

.pagination-wrapper {
  position: absolute;
}
.pagination{
  justify-content: center;
  --bs-pagination-padding-x:0.85rem;
  .page-link,.page-item:first-child .page-link,.page-item:last-child .page-link{
    border-radius: 50%;
  }
  .page-link{
    margin-inline: .5rem;
  color: var(--comet) !important;

  }
  .active > .page-link {
    background-color: var(--color-primary) !important;
    color: #FFFFFF !important;
    border: var(--color-primary);
  }
 
}


.block-navigation {
  pointer-events: none;
}

.g-recaptcha {
  margin: 0.5em auto;
  
}
.p-button.p-button-outlined ,.p-button.p-button-outlined:enabled:hover{
  color: var(--allGreen);
  
}
.p-button.p-button-outlined:enabled:hover,.p-button.p-button-outlined:enabled:focus{
  background:#68a5932f;
}

.card-header_title{
  display: flex;
  flex-direction: row;
  font-family: GraphikBold, sans-serif;
  color: var(--comet);
  font-size: 1.5em;
  gap: .5em;
  h4{
  margin-bottom: -.5rem;
  }
}