.callback-body {
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 1em;

  .dialog-footer {
    padding-top: 2em;
  }
}

.callback-body_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-family: BookFont, sans-serif;
}
.header_title{
  font-size: 1.2rem;
  .icon{
    margin-inline-end: .5rem;
  }
}

.title_icon{
  display: flex;
  align-items: center;
  .title{
    margin-inline-end: .5rem;
    font-size: 1.3rem;
    font-weight: bold;
  }
  .icon{
    cursor: pointer;
  }
}

.edit-callBack{
  cursor: pointer;
  margin-inline-start: 1rem;
}

.callback-note{
  background-color: rgba(69,131,170,0.14);
border: 1px solid rgba(69,131,170,0.34);
border-radius: 3px;
padding: 15px 20px;
display: block;
}

.content-callback{
  span{
    cursor: pointer;
    color: var(--iosBlue);
    text-decoration: underline 1px ;
  }
  span:hover{
    color :var(--bs-link-hover-color)
  }
  div{
    font-weight: 900;
    font-size: 1.2rem;
  }
}