.statistics {
  width: 100%;
  height: calc(100% - 5em);
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  gap: 1em;
  overflow-y: scroll;
  scrollbar-width: none !important;
}

.statistics::-webkit-scrollbar {
  display: none !important;
}

.statistics_filter {
  width: 100%;
  height: 5em;
}

.statistics_row {
  display: flex;
  flex-direction: row;
  gap: 1em;
  width: 100%;
}

.pad {
  padding-bottom: 5em;
}

.monthly-growth-container {
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .statistics {
    padding: 1em 0.5em;
  }
  .statistics_row {
    display: flex;
    flex-direction: column;
  }


}