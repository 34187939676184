
@font-face {
  font-family: BoldFont;
  src: url( '../fonts/MetroSans/MetroSans-Bold.otf' );
}

@font-face {
  font-family: MediumFont;
  src: url( '../fonts/MetroSans/MetroSans-Medium.otf' );
}

@font-face {
  font-family: BookFont;
  src: url( '../fonts/MetroSans/MetroSans-Book.otf' );
}

@font-face {
  font-family: GraphikBold;
  src: url('../fonts/Graphik/Graphik-Bold/Graphik-Bold.otf');
}

@font-face {
  font-family: RegularGraphik;
  src: url('../fonts/Graphik/Graphik-Regular/Graphik-Regular-v1.otf');
}
@font-face {
  font-family: MediumGraphik;
  src: url('../fonts/Graphik/Graphik-Medium/Graphik-Medium-v1.otf');
}