
.create-payment {
  width: 100%;
  height: calc(100% - 4em);
  padding: 2em;
}

.create-payment_card {
  background-color: #ffffff;
  padding: 1em;
  height: 100%;
  width: 80%;
  margin: auto;

}

.create-payment_card_header {
  text-align: center;
  border-bottom: 1px solid var(--colorLine);
  margin-bottom: 1em;
  padding: 1em 0;
}

.create-payment_card_header h1 {
  font-size: 1.2em;
  font-family: GraphikBold, sans-serif;
  color: var(--color-primary);
}

.create-payment_card_body, form {
  height: 100%;
  position: relative;
}

.create-payment_card_footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--colorLine);
  padding: 1em 0;
  position: absolute;
  bottom: 10%;
  width: 100%;
}
