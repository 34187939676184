.invoice_item {
  background-color: var(--selago);
  padding: 1em;
  height: 5em;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5em;
  align-items: center;
  width: 100%;
}

.add_body_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;

}

.item-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.item-row td {
  vertical-align: middle;
}

.item-row td:nth-child(1) {
  width: 15%;
}

.item-row td:nth-child(2) {
  width: 35%;
}

.item-row td:nth-child(3) {
  width: 15%;
}

.item-row td:nth-child(4) {
  width: 15%;
}

.item-row td:nth-child(5) {
  width: 15%;
}

.item-row td:nth-child(6) {
  width: 5%;
  cursor: pointer;
}





