.cropper-wrapper {
  width: 100%;
  height: 100%;
  padding: 1em;
}

.cropper-image-container {
  width: 10em;
  height: 10em;
  border: 2px solid var(--color-primary);
  border-radius: 15px;
  margin-left: auto;
}


.image-container {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.image-container_icon {
  position: absolute;
  bottom: 1em;
  right: 1em;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 15px;
}

.drag-container {
  min-width: 400px;
  min-height: 400px;
  border: 2px dashed var(--comet);
  display: grid;
  border-radius: 15px;
  place-items: center;
  cursor: pointer;
}

.drag-container p {
  font-family: BoldFont, sans-serif;
  color: var(--comet);
  text-align: center;
}


@media only screen and (max-width: 1024px) {
  .cropper-image-container {
    margin: auto;
  }
  .drag-container {
    min-width: 100px !important;
    width: 100%;
    height: auto;
  }
}