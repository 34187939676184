.payments-page {
  width: 100%;
  height: calc(100% - 5em);
  overflow-y: scroll;
  scrollbar-width: none !important;
}

.payments-page::-webkit-scrollbar {
  display: none !important;
}
 