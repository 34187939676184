.table-payments {
  height: calc(100vh - 455px);
  overflow-y: auto;
  position: relative;
  margin-block-end: 1.5vh;
}

.table-payments thead {
  width: 100%;
  background-color: #FFFFFF !important;
}

.table-payments thead th {
  position: sticky;
  padding: 1em;
  top: 0;
  z-index: 2;
  background-color: #FFFFFF !important;
}

.table-payments td {
  font-size: 0.9em;
  color: var(--olsoGray) !important;
  height: 50px;
  vertical-align: middle;
}


.table-payments table::-webkit-scrollbar {
  display: none !important;
}

.table-payments tbody img {
  width: 4em;
  height: 4em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -2em;
  margin-top: -2em;
}

@media only screen and (max-width: 1024px) {
  .table-payments td:nth-of-type(1):before {
    content: "";
  }

  .table-payments td:nth-of-type(2):before {
    content: "Date";
  }

  .table-payments td:nth-of-type(3):before {
    content: "Customer";
  }
  .table-payments td:nth-of-type(4):before {
    content: "Amount";
  }
  .table-payments td:nth-of-type(5):before {
    content: "Statue";
  }
  .table-payments td:nth-of-type(6):before {
    content: "";
  }

}