.users {
  width: 100%;
  height: calc(100% - 4em);
  padding: 2em;

  .errors {
    position: absolute;
  }
}


.users_card {
  position: relative;
  background-color: #ffffff;
  height: 100%;
  padding: 1em;
  border: 1px solid var(--colorLine);
  border-radius: 15px;
  min-height: 30em;
}

.users_card_header {
  padding: 1em 0;
  border-bottom: 1px solid var(--colorLine);
  margin-bottom: 1em;
  display: flex;
  align-items: center;
}

.users_card_body_filter {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: .75rem;

}

.users_card_body_table {
  margin-top: 1em;
}

@media only screen and (max-width: 1024px) {

  .users {
    height: auto !important;
    padding: 1em 0.5em;
  }
  
  #create_user_btn {
    position: absolute;
    top: 15px;
    right: 5px;
  }
  .users_card_body_filter{
    justify-content: space-between;
    margin-block: .75rem;
  }
}
@media only screen and (max-width: 560px) {
  .users_card_body_filter{
    flex-direction: column-reverse;
    button {
      width: 100%;
      justify-content: center;
    }
  }
}

.user-modal {
  .form-group {
    margin-bottom: 2em;
  }

  .errors {
    position: absolute;
  }
}

#loader {
  height: 120%;
  width: 90%;
  position: absolute;
  top: -10%;
  opacity: 0;
}

.showLoader {
  opacity: 1 !important;
}

