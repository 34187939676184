.security-body {
  padding: 1em;
  width: 100%;
  height: 100%;
}

.security-body_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;

}

.security-body_item_title {
  font-size: 1em;
  color: var(--comet);
  font-family: BoldFont, sans-serif;
}

.security-body_item_action {
  font-size: 1em;
  color: var(--comet);
  font-family: BookFont, sans-serif;
  text-decoration: underline;
  padding: 0.5em 1em;
  cursor: pointer;
}

.security-body_item_action:hover {
  background-color: var(--comet);
  color: #ffffff;
  border-radius: 0.75rem;
  border: 1px solid var(--lineColor);

}

@media only screen and (max-width: 1024px) {
  .security-body {
    min-height: calc(100vh - 8em);
  }
}