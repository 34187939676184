
.wrapper-confirmation {
  width: 100%;
  height: 100%;
  background: rgb(243, 246, 250) linear-gradient(180deg, rgba(243, 246, 250, 1) 79%, rgba(120, 132, 250, 1) 79%);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  overflow-y: hidden;

  .reset-form {
    background-color: #ffffff !important;
  }

  :root {
    --business-color: #5F6285;
    --background-color-1: #7884fA;
    --background-color-2: #F3F6FA;
  }

  body,
  html {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  .cursor {
    cursor: pointer;
  }

  .cursor-normal {
    cursor: default;
  }

  .business-title {
    font-size: 1.3em;
    font-family: GraphikBold, Times, serif;
    color: var(--business-color);
    margin: 0.5em 0 1em 0;
  }

  .illustration {
    position: absolute !important;
    width: 100%;
    height: 120%;
    object-fit: contain;
    right: -100%;
    top: -35%;
    z-index: 1 !important;

  }

  .illustration img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }


  .content-container {
    display: flex;
    flex-direction: row;
    position: relative;
    flex: 0 0 24%;
    z-index: 999;


  }

  .form-container {
    width: 100%;
  }

  .logo {
    width: 6em;
    height: 6em;
    padding: 0.5em;
    margin: auto;
  }

  .logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .form-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 0;
    margin-bottom: 2em;
  }

  .form-header span {
    margin: 0;
    color: var(--business-color);

  }

  .welcome-text {
    font-family: BoldFont, Times, serif !important;
    font-size: 1.3em;

  }

  .instruction-text {
    font-family: BookFont, Times, serif !important;
    font-size: 1.3em;
  }

  .form-wrapper {
    background-color: #fff;
    border-radius: 50px 50px 0 0;
    padding: 2.5em 1.5em;
    gap: 1em;
    height: 60vh;
    z-index: 99999;
    position: relative !important;

  }

  .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .form-group label {
    margin-bottom: 0.5em;
    font-family: RegularGraphik, Times, serif !important;
    color: var(--business-color);
    font-size: 13pt;
  }

  .help-section span {
    color: var(--business-color) !important;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 4em;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: var(--business-color);
    font-family: RegularGraphik, Times, serif !important;
    font-weight: 700;
    background-color: var(--background-color-2);
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: 0.5rem;
    box-sizing: border-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .form-control::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  .form-control:focus {
    color: #495057;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }

  .page-info {
    margin: 0;
    line-height: 1.2;
    text-align: center;
    z-index: 9999;
  }


  .graphik-m-font {
    font-family: BoldFont, serif !important;
    font-size: 16pt;
  }

  .graphik-r-font {
    font-family: RegularGraphik, serif !important;
    font-size: 16pt;
  }


  .btn {
    width: 100%;
    border: none;
    text-align: center;
    padding: 1em 1.5em;

  }

  .login-btn {
    color: #fff;
    background-color: var(--business-color);
    border-radius: 10px;
    font-size: 1.2em;
    margin: 2.5em 0 1em;
    box-sizing: border-box;
  }

  .login-btn:active {
    border: 2px solid var(--background-color-2);

  }

  .login-btn:focus {
    border: 2px solid var(--background-color-2);
  }

  .checkbox-group {
    margin-top: 5px;
  }

  .checkbox-group label {
    margin: 0;
  }

  .help-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
  }

  .help-section span:nth-child(1) {
    font-size: 0.9em;
    font-weight: 900;
    font-family: BoldFont, Times, serif !important;
    color: #a2a2a3;
  }

  .help-section span:nth-child(2) {
    font-size: 0.9em;
    font-weight: 200;
    font-family: BookFont, Times, serif !important;
    color: #b6b6b7;
  }

  .checkbox-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 1em;


  }

  .checkbox-group span {
    font-family: BookFont, Times, serif !important;
    color: var(--business-color);
    font-size: 0.9em;
  }

  .round {
    position: relative;
    display: grid;
    place-items: center;
  }

  .round label {
    background-color: #fff;
    border: 1.5px solid var(--business-color);
    border-radius: 50%;
    cursor: pointer;
    height: 12px;
    left: 0;
    position: absolute;
    width: 12px;
  }


  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: var(--business-color);
    border-color: #000;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  .illustration-mobile {
    position: absolute;
    right: -50%;
    margin-right: 5vw;
    top: -2%;
  }
}

.wrapper-confirmation * {
  box-sizing: border-box;
}


@media only screen and (max-width: 1440px) {

  .wrapper-confirmation {
    overflow: hidden;

    .content-container {
      flex: 0 0 30%;
    }

    .logo {
      width: 5em;
      height: 5em;
    }

    .business-title {
      font-size: 1.2em;

    }

    .form-header span {
      font-size: 1.2em;
    }

    .illustration {
      right: -100%;
    }


  }

}


@media only screen and (max-width: 768px) {
  .wrapper-confirmation {
    align-items: flex-start;
    position: relative;
    background: none var(--background-color-1) !important;
    background-color: var(--background-color-1) !important;

    .business-title {
      color: #fff;
      display: none;
    }

    .page-info {
      text-align: left;
    }

    .content-container {
      margin-top: 30%;
      height: 100%;
      flex: 0 0 100%;
    }

    .form-container {
      padding: 0 2em;
      position: relative;

    }

    .form-group label {
      font-size: 0.9em;
    }

    .logo {
      width: 5em;
      height: 6em;
      margin: 0 0 0.5em 0;
    }

    .form-header {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 1.5em;
      margin-bottom: 4em;
    }

    .form-wrapper {
      height: 100%;
    }

    .form-header span {
      color: #fff;
      font-size: 1.1em;
      z-index: 2;
    }


    .form-control {
      height: 3em;
    }

    .btn {
      padding: 0.5em 1em;
    }

    .login-btn {
      margin-top: 2em;
    }
  }


}

@media only screen and (max-width: 1024px) and (min-width: 768px) {

  .wrapper-confirmation {
    align-items: flex-start;
    grid-template-columns: 1fr;
    position: relative;
    background: none var(--background-color-1) !important;
    background-color: var(--background-color-1) !important;

    .business-title {
      color: #fff;
      font-size: 1.8em;
    }

    .page-info {
      text-align: left;
    }

    .illustration {
      right: 0;
      top: 0%;
      right: -44%;
      height: 100%;

      z-index: 1 !important;

    }

    .illustration img {
      object-fit: contain;
    }


    .content-container {
      width: 100%;
      height: 100%;
      margin-top: 30%;
      flex: 0 0 100%;
    }

    .form-container {
      width: 100%;

    }


    .form-container {
      padding: 0 2em;
      position: relative;
    }

    .form-group label {
      font-size: 1.5em;
    }

    .form-control {
      height: 4em;
    }

    .login-btn {
      font-size: 1.5em;
    }

    .logo {
      width: 8em;
      height: 8em;
      margin: 0 0 0.5em 0;
    }

    .form-header {
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 1.5em;
      margin-bottom: 4em;
    }

    .form-wrapper {
      height: 100%;
      position: relative;

    }

    .form-header span {
      color: #fff;
      font-size: 2em;
      z-index: 2;
    }
  }


}

@media only screen and (max-width: 500px) {
  .form-wrapper {
    .illustration {
      right: 0;
      top: -16%;
      right: -44vw;

      z-index: 1 !important;
    }

    .login-btn {
      border-radius: 5px;
    }

    .illustration img {
      object-fit: contain;
    }

    .form-wrapper {
      border-radius: 40px 40px 0 0;
    }

    .form-control {
      font-size: 10pt;
    }

    .field {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0;
    }
  }

}

.login-link {
  text-decoration: none;
  font-family: BoldFont, sans-serif, serif;
  color: var(--background-color-1);
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .login-link {
    color: var(--business-color);
  }
}