.spare_btn {
  border: none;
  border-radius: 5px;
  padding: 0.5em 1em;
  height: 3em;
  font-size: 0.9em;
  font-family: BoldFont, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
}


.spare_btn_primary {
  background-color: var(--color-primary);
  width: 130px;
  color: #ffffff;
  position: relative;
}

.spare_btn_login {
  background-color: var(--color-primary);
  width: 100%;
  color: #ffffff;
  position: relative !important;
}

.spare_btn_login:focus {
  background-color: var(--color-primary);
  width: 100%;
  color: #ffffff;
}

.spare_btn_login:hover {
  background-color: var(--color-primary);
  width: 100%;
  color: #ffffff;
}

.spare_btn_primary:hover {
  background-color: var(--color-primary);
}

.spare_btn_primary:focus {
  background-color: var(--color-primary);
}

.spare_btn_cancle {
  background-color: var(--manhattan);
  width: 130px;
  color: #ffffff;
}

.spare_btn_cancle:hover {
  background-color: var(--manhattan);
}

.spare_btn_cancle:focus {
  background-color: var(--manhattan);
}

.spare_btn_action {
  background-color: var(--comet);
  width: 150px;
  color: #ffffff;
  font-size: 0.8em;
}

.action_btn {
  background-color: var(--color-primary);
  width: 150px;
  color: #ffffff;
  font-size: 0.8em;
  transition: background-color 250ms ease-in;
}

.action_btn:hover {
  background-color: #4f5170da !important;
}

.spare_btn_action:hover {
  background-color: var(--comet);
}

.spare_btn_action:focus {
  background-color: var(--comet);
}

#dropdown-basic {
  background-color: transparent;
  border: none;
  color: #000;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: auto;
}

.dropdown-item {
  padding: 0.8em;
  display: flex;
  flex-direction: row;
  gap: 1em;
  max-width: 12em;
  width: 12em;
  font-family: BoldFont, sans-serif;
  font-size: 0.9em;
}

.dropdown-item:focus {
  background-color: #ffffff;
  color: var(--color-primary);
}

.dropdown-item_icon {
  width: 2em;
  color: var(--comet);
}

.disabled {
  pointer-events: none !important;

}

.spare_btn_interaction {
  background-color: transparent;
  color: var(--comet);
  cursor: pointer;
  text-decoration: underline;
}

.spare_btn_interaction:hover {
  background-color: var(--comet);
  border: 1px solid var(--comet);
}

.spare_btn_interaction:focus {
  background-color: var(--comet);
  border: 1px solid var(--comet);
}

.remove_btn,
.remove_btn_option {
  width: 1.5em !important;
  height: 1.5em !important;
  border-radius: 50%;
  background-color: var(--comet);
  color: #ffffff;
  display: grid;
  place-items: center;
  box-shadow: 3px 3px 2px rgba(0, 0, 0, 0.15);
}

.mobile-menu-actions {
  width: 100% !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5em;
  font-size: 1.4em;
  border: 2px solid var(--comet) !important;
  border-radius: 0 0 10px 10px;
  color: var(--comet);
}

.mobile-menu-actions a {
  width: 1em;
  font-size: 1.2em;
}

.btn:disabled {
  background-color: var(--olsoGray) !important;
}

.spare-dropdown button:disabled,
.spare-dropdown button:active {
  box-shadow: none;
}

.spare-dropdown button,
.spare-btn {
  border: none;
  padding: .625em 1.25em;
  cursor: pointer;
  border-width: 1px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 20px -6px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  position: relative;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
  // label style
  font-size: 1em;
  font-weight: 700;
  color: #fff;

  //children style
  display: flex;
  align-items: center;
  gap: .75rem;
}

.spare-dropdown {
  button {
    background-color: var(--color-primary);
  }

  button:hover,
  button:focus {
    background-color: var(--color-primaryHover);
  }

  .dropdown-menu {
    margin-block-start: .2rem;
    background: var(--color-primary);
    border: none;

    .dropdown-item {
      -moz-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      -webkit-transition: all 0.3s ease;
      -ms-transition: all 0.3s ease;
      transition: all 0.3s ease;
      color: white;
    }

    .dropdown-item:hover{
      background-color: #5b688e;
    }
  }
}