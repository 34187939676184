.payment-detail-container {
  width: 80%;
  margin: 2em auto;
  height: calc(100% - 4em);
  background-color: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  padding: 0;
}

.payment-detail {
  width: 100%;
  overflow-y: hidden;
  height: calc(100% - 4em) !important;

}

.payment-detail_header {
  height: 5em;
  background-color: var(--color-primary);
  color: #ffffff;
  font-family: GraphikBold, sans-serif;
  font-size: 1.2em;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 1em;
}

.payments-detail-actions {
  position: absolute;
  top: 1em;
  right: 1em;
  display: flex;
  flex-direction: row;
  gap: 1em;
}

.payment-detail_body {
  display: flex;
  flex-direction: column;
  padding: 1em 2em;
}

.payment-detail_body_item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0.2em 0;
  border-bottom: 1px solid var(--colorLine);
  height: 3em;
}

.no-border {
  border-bottom: none;
}

.payment-detail_body_item_title {
  font-family: BoldFont, sans-serif;
  margin: 0;
  width: 120px;
}

.payment-detail_body_item_description {
  margin: 0;
  font-family: BookFont, sans-serif;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5em;
  width: 50%;
}

.text-copy {
  color: #004cef;
  cursor: pointer !important;
  text-underline: none;
}

.debtor-header {
  padding: 1em 0;

}

.debtor-header h5 {
  font-family: BoldFont, sans-serif;
}

@media only screen and (max-width: 1024px) {
  .payment-detail-container {
    width: 95%;
    height: auto;
  }
}