.waiting-container {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.waiting-logout {
  font-size: 1em;
  font-family: BookFont, sans-serif;
  text-align: center;
  position: absolute;
  top: 1em;
  right: 1.5em;
  color: #4a64f5;
  cursor: pointer;
  padding: 1em 0;
  background-color: transparent;
}

.waiting-message {
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  display: grid;
  place-items: center;
  position: relative;
}

.waiting-message h4 {
  font-size: 1.2em;
  font-family: GraphikBold, sans-serif;
  color: var(--comet);
  z-index: 999;
  line-height: 2em;
}

.waiting-image {
  width: 12em;
  height: 100%;
  position: absolute;
  z-index: 1;
  right: 1em;
  bottom: -10em;
}

.waiting-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.waiting-image-container {
  width: 50%;
  min-height: 6em;
  height: 30em;
  margin: auto;

}

.waiting-image-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.waiting-message-title {
  font-size: 1em;
  font-family: BoldFont, sans-serif;
  margin-bottom: 1em;

}
.waiting-message-block {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.waiting-message-text {
  font-size: 1em;
  font-family: BoldFont, sans-serif;
}

.waiting-social-links {

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5em;
}

@media only screen and (max-width: 1024px)
{
  .waiting-image-container {
    height: 100%;
  }
  .waiting-message h4 {
    margin-top: 2.5em;
  }
}