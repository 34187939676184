.payments {
  width: 100%;
  height: calc(100% - 4em);
  padding: 2em;
}

.payments_payment-card {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1em;
  border: 1px solid var(--colorLine);
  border-radius: 0.75rem;

}

.payments_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 0;
  border-bottom: 1px solid var(--colorLine);
  margin-bottom: 1em;
}


.payments_header_action {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: center;
  
}

.payments_header_action_export button {
  background-color: var(--comet) !important;
}

.payments_header_action_export:hover {
  background-color: var(--comet) !important;
}

.payments_header_action_export:focus {
  background-color: var(--comet) !important;
}

.action_menu {
  background-color: var(--comet) !important;
}

.action_item {
  color: #ffffff !important;
}

.action_item:hover {
  background-color: var(--color-primary) !important;
}

.payments_body_filter {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.payments_body_filter_date-picker {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
}




.payment-status-dot {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  margin-right: 5px;
}


.payments_body_filter_status {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

.payments_body_filter_status_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.2em;
}

.payments_body_table {
  margin-top: 1em;
}




.refresh_icn{
  color: var(--color-primary);
}



@media only screen and (max-width: 1024px) {
  
  .payments {
    height: auto;
    padding: 1em 0.5em;
  }
  .payments_header {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .payments_header_title {
    justify-content: flex-start !important;
    width: 100%;
  }
  .payments_body_filter {
    flex-direction: column;
    margin-bottom: 1em;
    gap: 1em;
  }
  .payments_body_filter_date-picker {
    font-size: 0.8em;
  }
  .payments_header_action{
    width: 100%;
    justify-content: space-between;   
   }
  
}

@media screen and (max-width:780px) {
  .payments_header_action{
      flex-direction: column-reverse;
      .actions{
        width: 100%;
        *{width: 100%;}
      }
      .filter-search{
      width: 100%;
      }
    }
}