$primary: var(--comet);
$white: #fff;
$black: #000;
$secondary: var(--manhattan);
$warning: rgba(218, 58, 58, 0.753);

.bank-account {
    padding: .1rem 2rem;

    height: 100%;

    .actions {
        width: 100%;
        gap: 1rem;

        margin-bottom: 1rem;
        display: flex;
        justify-content: flex-end;

        .add-account_btn,
        .delete-account_btn,
        .manage-account_btn {
            color: $white;
            border-radius: 10px;
            text-transform: capitalize;
            text-decoration: none;
            padding: .5rem 1rem;
            border: 2px solid transparent;
            display: inline-block;
            transition: all 0.4s ease 0s;
            font-size: larger;
            font-weight: 700;

            span {
                margin-left: .75rem;
            }
        }

        .add-account_btn {
            background: $primary;
        }

        .manage-account_btn {
            background: $secondary;
        }

        .delete-account_btn {
            margin-left: 1rem;
            background-color: $warning;
        }

        .add-account_btn:hover,
        .delete-account_btn:hover,
        .manage-account_btn:hover {
            transition: all 0.4s ease 0s;
        }

        .add-account_btn:hover {
            color: $primary;
            background: #fff;
            border-color: $primary;
        }

        .manage-account_btn:hover {
            color: $secondary;
            background: #fff;
            border-color: $secondary;
        }

        .delete-account_btn:hover {
            color: $warning;
            background: #fff;
            border-color: $warning;
        }
    }

    .account_card {

        width: 100%;
        height: 90%;
        padding: 1.5rem 2rem;

        .bank-info {
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                border-radius: 1rem;
                width: 100%;
                max-width: 28rem;
                height: auto;
                object-fit: contain;
            }

            &_name {
                color: $black;
                font-size: 1.8rem;
                text-transform: uppercase;
                font-weight: bold;
            }
        }

        hr {
            width: 100%;
            border: .1rem solid $black;
            border-radius: 5px;
            margin-bottom: 1rem;
        }

        .account-info {
            color: $black;
            margin-bottom: 1rem;

            .tile {
                font-size: 1.2rem;

                .title {
                    font-weight: bold;
                }

                .content::before {
                    content: " ";
                }
            }
        }

    }


}

.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;

    &-title,
    &-icon {
        color: rgba(128, 128, 128, 0.445);
        text-align: center;
    }

    &-title {
        font-size: 3rem;
        font-weight: bold;
    }

    &-icon {
        font-size: 7rem;
        padding-bottom: 2rem;
    }

}

@media screen and (max-width:1024px) {
    .actions {
        padding-top: 1rem;
    }
}

.loading {

    margin-top: 12vh;
    justify-content: center;
    align-items: center;
    &_img_title {
        margin-bottom: 8vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img {
            margin-bottom: 1rem;
        }
    }
    &_info{
        .identifier{
            margin-bottom: .75rem;
        }
    }
}