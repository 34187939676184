.dashboard {
  width: 100%;
  height: calc(100% - 5em);
  display: flex;
  flex-direction: column;
  gap: 1em;
  padding: 1em 2em 5em;
  overflow-y: scroll;
  scrollbar-width: none !important;
}

.dashboard::-webkit-scrollbar {
  display: none !important;
}


.dashboard_charts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
}

@media only screen and (max-width: 1024px) {
  .dashboard_charts {
    flex-direction: column !important;
  }
  .dashboard {
    padding: 1em 1em 5em;
  }
}

