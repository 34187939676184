.table-users td {
  font-size: 0.9em;
  color: var(--olsoGray) !important;
  height: 50px;
  vertical-align: middle;

}

.table-users .dropdown {
  margin-left: auto;
}

.table-users tbody img {
  width: 4em;
  height: 4em;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2em;
  margin-left: -2em;
}

@media only screen and (max-width: 1024px) {

  .table-users td:nth-of-type(1):before {
    content: "";
  }

  .table-users td:nth-of-type(2):before {
    content: "Name";
  }

  .table-users td:nth-of-type(3):before {
    content: "Role";
  }
  .table-users td:nth-of-type(4):before {
    content: "Email";
  }
  .table-users td:nth-of-type(5):before {
    content: "State";
  }
  .table-users td:nth-of-type(6):before {
    content: "registred at";
  }
}