.loader{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin-block: 2rem;
    img{
      width: 8rem;
      max-width: 100%;
      height: auto;
    }
  }