.bar-chart {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border: 1px solid var(--colorLine);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 1em;
  gap: 1em;

}

.bar-chart_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
}

.bar-chart_header_title {
  font-size: 1.2em;
  color: var(--comet);
  font-family: GraphikBold, sans-serif;
}

.bar-chart-container {
  width: 100%;
  height: 100%;

}