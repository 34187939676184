form {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-top: 0.5em;
}

.form-group {
  margin-bottom: 2em !important;
}

.form-select {
  background-color: var(--selago) !important;
  height: 50px !important;
  border-color: var(--colorLine) !important;
  box-shadow: none !important;
  font-family: BoldFont, sans-serif !important;
  width: 100% !important;
}

.form-control {
  background-color: var(--selago) !important;
  height: 50px !important;
  border-color: var(--colorLine) !important;
  box-shadow: none !important;
  font-family: BoldFont, sans-serif !important;
  width: 100% !important;
}

.form-control:focus {
  border-color: var(--color-primary) !important;
  box-shadow: none;
  background-color: var(--selago);
}

.form-control:active {
  border-color: var(--color-primary) !important;
  box-shadow: none;
  background-color: var(--selago);
}

.form-label {
  font-family: BoldFont, sans-serif;
  font-size: 0.95em;
}





.payment-status-dot {
  width: 0.5em;
  height: 0.5em;
  border-radius: 50%;
  margin-right: 5px;
}



.form-multi-select_input .dropdown-heading {
  height: 50px;
  background-color: var(--selago);
}

.errors {
  color: var(--cranberry);
  font-family: BoldFont, sans-serif;
  font-size: 0.9em;
}

.custom-toggle.react-toggle--checked .react-toggle-track {
  background-color: var(--comet);
  border-color: var(--comet);
}

.react-toggle-track {
  background-color: var(--colorLine);
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: var(--comet);
}

.react-toggle--focus {
  box-shadow: none !important;
}

.react-toggle:focus {
  box-shadow: none !important;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.form-full-flex {
  flex: 1 1 100% !important;
}

.http-form-control {
  display: grid;
  width: 100%;
  grid-template-columns: 13% 87%;
  grid-template-rows: 1fr;
}



.required {
  color: var(--blushPink);
  padding-left: 0.5em !important;
}





@media only screen and (max-width: 1024px) {
  .filter_search_input {
    width: calc(100vw - 5em);
  }
  #dropdown-basic {
    display: none;

  }
}
