.no_data {
    margin-block-start: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        max-width: 15em;
        height: 100%;
    }
}