
.dailog-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
}

.dailog-header_title {
  font-family: GraphikBold, sans-serif !important;
  font-size: 1.2em;
  color: var(--comet);
}


.dailog-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1.5em;
}


.dialog-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dialog_warning-message_wrapper {
  margin: auto;
}

.dialog_warning-message {
  font-family: BoldFont, sans-serif;
  padding: 1em;
  background-color: var(--selago);
  border-radius: 5px;
  color: var(--blushPink);
}

.dialog_enable-message {
  font-family: BoldFont, sans-serif;
  padding: 1em;
  background-color: var(--selago);
  border-radius: 5px;
  color: var(--success);
}


@media only screen and (max-width: 1024px) {
  .modal {
    width: 100vw;
  }
  .dailog-body {
    flex-direction: column;
    width: 100%;
  }
  
}
.dailog-body * {
  width: 100%;
}
.dailog-body .errors {
  position: absolute;
}

