.register-form {
  width: 100%;
  height: 100%;
  padding: 1em;
}

.register-card {
  width: 60%;
  height: 95%;
  margin: auto;
  border-radius: 25px;
  background-color: #FFFFFF;
  padding: 1em;
  display: grid;
  place-items: center;
  overflow-y: hidden;
  position: relative;
  box-shadow: 10px 10px 4px rgba(182, 181, 186, 0.1);
}

.step::-webkit-scrollbar {
  display: none;
}

.step {
  width: 80%;
  height: 95%;
  display: flex;
  flex-direction: column;
  gap: 2em;
  transition: opacity 0.5s ease-in-out;
  animation: fade 0.5s ease-in-out;
  overflow-y: scroll;
  padding-bottom: 2em;
  margin-top: 4em;
  scrollbar-width: none;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

.text-highlight {
  color: var(--lightRoyalBlue) !important;
  cursor: pointer;
}

.register-dec {
  position: absolute;
  width: 10em;
  height: 6em;
  right: 0;
  top: 0;

}

.register-dec img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.register-title {
  position: relative;
  margin-top: 5em;
}

.register-title h4 {
  font-size: 2em;
  font-family: BoldFont, sans-serif;
  color: var(--color-primary)

}

.register-national-id {
  color: var(--lightRoyalBlue_50);
  cursor: pointer;
}

.register-back {
  position: absolute;
  left: 5%;
  top: 5%;
  width: 1em;
  height: 1em;
  font-size: 2em;
  cursor: pointer;
}

.register-back img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.upload-text {
  color: var(--comet);
  cursor: pointer;
}

#loader {
  height: 120%;
  width: 90%;
  position: absolute;
  top: -10%;
  opacity: 0;
}

.showLoader {
  opacity: 1 !important;
}

.register-form .form-control {
  background-color: #FFFFFF !important;
}

.register-form .spare_btn {
  width: 80%;
  margin: auto;
}

.register-form .spare_btn:hover {
  width: 80%;
  margin: auto;
}

.register-form .spare_btn:focus {
  width: 80%;
  margin: auto;
}

.register-form .selected-flag {
  background-color: #F4F2FF;
}

.upload-helper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5em;
}

.upload-helper .upload-helper-text {
  background-color: var(--selago) !important;
  border-radius: 5px;
  padding: 0.4em;
  font-family: BoldFont, sans-serif;
  font-size: 0.8em;
  cursor: pointer;
}

.upload-helper .upload-helper-indic {
  color: var(--olsoGray);
}

@media only screen and (max-width: 1024px) {
  .register-card {
    width: 100%;
    height: 100%;

  }
  .register-form {
    padding: 0;
  }
  .step {
    width: 100%;
  }

}

.info-icon {
  padding-left: 1em;
}