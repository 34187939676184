.profile {
  width: 100%;
  height: 100%;
  padding: 1em;
}

.account_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 4em;
  border-bottom: 1px solid var(--colorLine);
  gap: 0.5em;
}

.account_header h4 {
  font-size: 1.2em;
  font-family: GraphikBold, sans-serif;
  margin: 0;
}

.dialog-footer {
  gap: 0.5em;
}

.profile_body {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 60% 40%;


}

.account_body_list {
  display: flex;
  flex-direction: column;
  padding-left: 2em;
  gap: 0.5em;
}

.account_body_item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3em;

}

.account_body_item label {
  font-family: BoldFont, sans-serif;
}


@media only screen and (max-width: 1024px) {
  .profile_body {
    display: flex;
    flex-direction: column-reverse;
  }
}

.modal-body img{
   width: 100%;
  height: 100%;
  object-fit: contain;
}