.datepicker {
  max-width: 23em;
  height: 2.5em;
  position: relative;
  cursor: pointer;
}


.datepicker_input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: transparent;
  color: var(--color-primary);
  gap: 0.5em;
  padding: 0.5em;
}

.datepicker_selector {
  position: absolute;
  display: none !important;
  z-index: 99999;
}

.datepicker_selector_visible {
  position: absolute;
  display: block;
  z-index: 99999;
  top: 3em;
}

@media screen and (max-width:595px) {
  .datepicker{
    max-width: 100%;
  }
  .datepicker_input{
    padding-inline: 1.25rem;
  }
}