.invoice_table td {
  font-size: 0.9em;
  color: var(--olsoGray) !important;
  height: 50px;
  vertical-align: middle;

}

.invoice_table tbody tr {
  cursor: pointer;
}

.invoice_table tbody img {
  width: 4em;
  height: 4em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -2em;
  margin-top: -2em;
}

@media only screen and (max-width: 1024px) {

  .invoice_table td:nth-of-type(1):before {
    content: "";
  }

  .invoice_table td:nth-of-type(2):before {
    content: "Customer";
  }

  .invoice_table td:nth-of-type(3):before {
    content: "Due Date";
  }
  .invoice_table td:nth-of-type(4):before {
    content: "Creation Date";
  }
  .invoice_table td:nth-of-type(5):before {
    content: "Expiration Date";
  }
  .invoice_table td:nth-of-type(6):before {
    content: "Total";
  }
}