$primary: var(--comet);
    $white: #fff;
    $black: #000;
    $secondary:var(--manhattan);

.header {
    color: $black;
    font-size: 1.8rem;
    font-weight: bolder;
    padding: 1rem 0 1rem 0;
}

.manage_content {
    margin: 0 1rem 1rem 1rem;

    .schema {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: row;
        margin-bottom: 1rem;

        .schema_iban,
        .schema_bban {
            display: inline-flex;
            label {
                font-size: 1.3rem;
                margin-right: .5rem;
            }

            input {
                display: none;
             }

            .custom-radio-btn{
                width: 30px;
                height: 30px;
                border: 2px solid #444;
                border-radius: 50%;
                margin-left: 10px;
                display: flex;
                justify-content: center ;
                align-items: center;
            }

            .custom-radio-btn .checkmark{
                width: calc(100% - 6px);
                height: calc(100% - 6px);
                background-color: $primary;
                border-radius: 50%;
                opacity: 0;
                transition: opacity 0.5s ease;
            }

            .custom-radio-btn input:checked + .checkmark{
                opacity: 1;
            }


        }
    }

    .inputer{
        display: flex;
        flex-direction: column;
        select{
            margin-bottom: .5rem;
            padding: .75rem;
            border: 1px solid black;
            border-radius: 10px;
            outline: none;
            background-color: transparent;
        }
        label{
            margin-bottom: .2rem;
            color: black;
            font-weight: bold;
        }
        input{
            margin-bottom: .5rem;
            padding: .5rem;
            border: 1px solid black;
            border-radius: 10px;
            outline: none;
        }
        input:hover ,select:hover{
            background-color: rgba(68, 68, 68, 0.096);
        }
        input:focus{
            border:2px solid $primary;
        }
        input:read-only{
            background-color: rgba(68, 68, 68, 0.096);
            cursor: default;
        }
        input:focus.invalid , input.invalid{
            border-color: red;
        }
        select.invalid{
            border-color: red;
        }
       
    }

    .footer{
        margin-top: 1.5rem;
        display: flex;
        gap: 1rem;
        flex-direction: row;
        justify-content: flex-end;

        button{
            cursor: pointer;
            border-radius: 10px;
            text-transform: capitalize;
            text-decoration: none;
            padding: .5rem;
            display: inline-block;
            transition: all 0.4s ease 0s;
            font-size: larger;
            font-weight: 700;
            border: 2px solid transparent;

        }
        .cancel{
            margin-right: 1rem;
        }
        .submiter{
            color: $white;
            background: $primary;
        }
        .submiter:hover{
            color: $primary;
            background: #fff;
            border-color: $primary;
            transition: all 0.4s ease 0s;
        }
    }
    .error{
        color: red;
    }
    
}
