.settings {
  padding: 1em;
}

.settings_card {
  padding: 1em 0;
  border: 1px solid var(--colorLine);
  border-radius: 0.75rem;
  width: 100%;
  height: calc(100% - 4em);
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-rows: 1fr;
  overflow: hidden;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .settings {
    padding: 0.3em;
  }
  .settings_card {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }
  .navigations {
    height: 0 !important;
  }
}