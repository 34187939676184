
.Navbar {
  display: flex;
  flex-direction: column;
  background-color: var(--color-primary);
  justify-content: flex-start;
}

.navbar_top-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5em;
  min-height: auto;
  height: 70px !important;
}

.mobile-navigation-btn {
  color: #ffffff;
  font-size: 1.5em;
  padding-inline-end: 0.5em;
  margin-inline-start: .75rem;
}

.navbar_top-navbar_logo {
  color: #ffffff;
  font-family: GraphikBold, sans-serif;
  height: 2em;
  display: flex;
  flex-direction: row;
  text-decoration: none;
  margin-left: 0.5em;
  align-items: center;
  line-height: 20px;
  flex: 0 0 15em;
}

.navbar_top-navbar_logo_text {
  font-size: 1.2rem;
  margin: 0;
}

.navbar_top-navbar_logo:hover {
  color: #ffffff;
}

.navbar_top-navbar_logo img {
  object-fit: contain;
  width: 3em;
  height: 100%;
}

.navbar_bottom-navbar {
  background-color: #ffffff;
  border-radius: 25px 0 0 0;
  height: 75px;
  box-shadow: -7px -7px 5px 4px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: -7px -7px 5px 4px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: -7px -7px 5px 4px rgba(0, 0, 0, 0.05);
  border-top: 2px solid var(--manhattan);
  border-left: 2px solid var(--manhattan);
}

.navbar_bottom-navbar_navigations-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1em 2em;
  width: 60%;
  margin: auto;
}

.navbar_bottom-navbar_navigations-list_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em 2em;
  width: 60%;
  height: auto;
  margin: auto;
  gap: 2em;
  color: #ffffff !important;
  position: absolute;
  top: 2em;
  left: -1em;
  animation: fade 0.6s ease-in-out;

}

.navbar_bottom-navbar_navigations-list_mobile .navbar-item-title, .navbar-item {
  color: #ffffff !important;
}

.navbar_bottom-navbar_navigations-list_mobile .navbar-item {
  color: #ffffff !important;
  justify-content: flex-start;
}

.navbar_bottom-navbar_navigations-list_mobile .navbar-item_active::before {
  top: 2em;
}


.navbar-item {
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  padding: 1em;
  justify-content: center;
  align-items: center;
  font-family: BoldFont, sans-serif;
  color: #000 !important;
  text-decoration: none !important;
  width: 7em;
}

.navbar-item:hover {
  color: #000;
  transform: translateY(-2px);
}

.navbar-item_active {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  padding: 1em;
  font-family: BoldFont, sans-serif;
  text-decoration: none !important;
  color: var(--manhattan);
  box-shadow: 0 2px 0 var(--manhattan);
  width: 7em;
}


.navbar-item_active:hover {
  color: var(--manhattan);
}


.navbar-item-title {
  font-size: 1em;
  text-align: center;
}


.navbar_top-navbar_settings {
  margin-inline-end: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;

}

.navbar_top-navbar_settings_toggle {
  background-color: transparent !important;
  border: none !important;
  padding: 0.5em !important;
}

.navbar_top-navbar_settings_toggle::after {
  color: #ffffff;
}

.navbar_top-navbar_settings_toggle:active {
  border: none !important;
}

.btn:focus {
  border: none !important;
  box-shadow: none !important;
}

.mobile-navigation {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--color-primary);
  animation: slide 0.2s ease-in;
  clip-path: circle(63.1% at 4% 26%);
}


.mobile-navigation_close_btn {
  color: #ffffff;
  font-size: 1.2em;
  position: absolute;
  top: 1em;
  left: 1em;
}

@keyframes slide {
  0% {
    height: 0;
  }
  100% {
    height: 100%
  }

}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }

}


.navbar_hamburgur{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: flex-end;
}
